import React, { useEffect, useState } from 'react'
import { lb_withdrawal_history, premium_transfer_history, withdrawal_history } from '../common/Api'
import { Link } from 'react-router-dom'
import Pagination from "react-js-pagination";
import { toast, ToastContainer } from 'react-toastify';
import Loader from '../common/Loader';

export default function LeaderShipHistoryWithdrawal() {
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [count, setCount] = useState(null)
    const [loader, setLoader] = useState(true)
    const [activePage, setActivePage] = useState(1);


    const get_withdrawal_history = async () => {

        try {
            const data = await lb_withdrawal_history(page)
            console.log(data)
            setData(data.Data)
            setCount(data.count)
            setLoader(false)

        } catch (error) {
            toast.error(error)
        }
    }

    useEffect(() => {
        get_withdrawal_history()
    }, [])

    if (loader) {
        return <Loader />
    }


    console.log(data)

    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <ToastContainer />
            <div class="row">
                <div class="col-lg-12">
                    <div class="card pt-4 mb-3 px-3">
                        <div className="table-responsive text-center">
                            <h4>Leadership Withdrawal History</h4>
                            <table className="table table-borderless border-top text-center">
                                <thead className="border-bottom text-center">
                                    <tr>
                                        <th>Sr no.</th>
                                        <th>Amount</th>
                                        <th>status</th>
                                        <th>Hash</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.map((e, index) => {
                                            return (
                                                <tr key={e.id}>
                                                    <td>{index + 1}</td>
                                                     
                                                    <td>{parseFloat(e.Withdraw_JW).toFixed(2)}</td>
                                                    <td>
                                                        {
                                                            e.status === 1 ? <><span className='btn btn-success waves-effect waves-light btn-xs'>Success</span></> :
                                                             e.status === 2 ? <><span className='btn btn-danger waves-effect waves-light btn-xs'>Reject</span> </> : <>
                                                             <span className='btn btn-warning waves-effect waves-light btn-xs'>Pending</span></>
                                                        }
                                                    </td>

                                                    <td>
                                                        {e.Transaction_Hash.slice(0, 20)} ******
                                                    </td>
                                                    <td><Link to={`https://bscscan.com/tx/${e.Transaction_Hash}`}>Go</Link></td>
                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>



                        </div>
                    </div></div></div></div>


    )
}
