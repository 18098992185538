import React from 'react'

export default function Maintenance() {
    return (
        <section className="singup_div bg-image">
            <div className="container item-center">
                <div class="otp_all_info">
                    <div class="maintenance text-center pb-4">
                        <h3 class="pb-2">App is Undergoing</h3>
                        <h1 class="maintenance_text">Maintenance Break</h1>
                        <div class="maintenance_img py-4">
                            <i class="fa-solid fa-screwdriver-wrench"></i>
                        </div>
                        <p class="pt-4">
                            We will Come with better performance Soon
                        </p>
                    </div>
                </div>
            </div>

        </section>
    )
}
