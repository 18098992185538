import React from 'react'
import WithdrawalHistoryComponent from '../components/WithdrawalHistoryComponent'

export default function WithdrawalHistoryPage() {
    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className='card mb-4 p-4'>
                <WithdrawalHistoryComponent />
            </div>

        </div>
    )
}
