import React from 'react'
import LoginComponents from '../components/LoginComponents'
import HomeComponents from '../components/HomeComponents'


export default function HomePage() {
  return (
    // <LoginComponents />
    <HomeComponents />
  )
}
