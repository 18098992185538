function formatDate(date) {
    const pad = (num, length) => num.toString().padStart(length, '0');
  
    const year = date.getFullYear();
    const month = pad(date.getMonth() + 1, 2); // Months are zero-based
    const day = pad(date.getDate()-1, 2);
    const hours = pad(date.getHours(), 2);
    const minutes = pad(date.getMinutes(), 2);
    const seconds = pad(date.getSeconds(), 2);
    const milliseconds = pad(date.getMilliseconds(), 3); // Only 3 digits for milliseconds
  
    // To achieve the 6-digit fractional seconds (milliseconds + microseconds)
    // JavaScript Date does not provide microseconds, so we'll use milliseconds and pad it to 6 digits
    const fractionalSeconds = pad(milliseconds, 6);
    //${hours}:${minutes}:${seconds}.${fractionalSeconds}
  
    return `${year}-${month}-${day}`;
  }

  export default formatDate;
