import React from 'react'

export default function DateFormatter({ timestamp }) {
    const date = new Date(timestamp);

    // Define an array of month names
    const monthNames = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    // Extract day, month, year, hours, and minutes
    const day = date.getDate();
    const month = monthNames[date.getMonth()]; // Gets month name
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, '0'); // Format hours
    const minutes = date.getMinutes().toString().padStart(2, '0'); // Format minutes

    // Format the date and time
    const formattedDateTime = `${day} ${month} ${year}, ${hours}:${minutes}`;

    return (
        <div>
            <p>{formattedDateTime}</p>
        </div>
    );
}


