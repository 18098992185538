import React from 'react';
import DashboardBodyComponents from './DashboardBodyComponents';

export default function DashboardComponents() {


    return (
        <DashboardBodyComponents />
    )
}
