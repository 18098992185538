import React from 'react'
import comingimg from '../admin_assets/assets/img/cm-bg.png'

export default function ComingSoonComponent() {
  return (
    <div class="content-wrapper d-flex justify-content-center w-100 ">
                    <div class="text-center">
                        <img src={comingimg} alt="" class="" />
                    </div>
                </div>
  )
}
