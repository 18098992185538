import React from 'react'
import InternalTransferHistoryComponent from '../components/InternalTransferHistoryComponent'

export default function InternalTransferHistoryPage() {
    return (
        <div className='container-xxl flex-grow-1 container-p-y'>
            <div className='card mb-4 p-4'>
                <InternalTransferHistoryComponent />
            </div>

        </div>

    )
}
