import React, { useEffect, useState } from 'react'
import { user_details_two, user_referal_history, user_reward_history } from '../common/Api'
import walletnew from '../admin_assets/assets/img/illustrations/wallet_new.webp'
import { useNavigate } from 'react-router-dom';
import DateFormatter from './common/DateFormatter';


export default function BalanceComponent() {
    const ph = JSON.parse(localStorage.getItem('ph'));
    const navigation = useNavigate();


    const [info, setInfo] = useState({})
    const [userreward, setUserReward] = useState([])
    const [userreferal, setUserReferal] = useState([])
    const [pageDetail, setPageDetail] = useState('wallet_page')



    const get_user_detail_two = async () => {
        const data = await user_details_two(pageDetail)
        setInfo(data)


    }

    const get_user_reward = async () => {
        try {
            const response = await user_reward_history()

            setUserReward(response.Data)
        } catch (error) {
            console.log(error)
        }
    }

    const get_referal_reward = async () => {
        try {
            const responses = await user_referal_history()

            setUserReferal(responses.Data)
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        get_user_detail_two();
        get_user_reward();
        get_referal_reward();


    }, [])

    // useEffect(() => {
    //     if (ph == 1) {
    //         navigation('/user/import/phrases')
    //     }

    // }, [ph])



    //console.log(info)

    //console.log(userreferal)




    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">


                <div class="col-lg-6 mb-4">
                    <div class="card px-4 pt-3 h-100">
                        <div class=" d-flex flex-column gap-2">
                            <h4 class="text-center">WALLET</h4>
                            <div class="text-center ">
                                <img src={walletnew} alt="" class="img-fluid wallet_new_img" style={{ maxWidth: "90px" }} /></div>
                            <div class="d-flex justify-content-between align-items-center mb-3">
                                <div class="text-center mb-3">

                                    <h5>Heath Reward</h5>
                                    <span>{info?.healthreward}</span>
                                </div>
                                <div class="text-center">

                                    <h5>Stake Credit</h5>
                                    <span>{info?.stake_credit}</span>
                                </div>
                                <div class="text-center">

                                    <h5>User Referral</h5>
                                    <span>{info?.Referral_balance}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 mb-4">
                    <div class="card px-4 pt-3 h-100">
                        <ul class="nav nav-tabs bg-transparent d-flex justify-content-center border-0">
                            <li class="nav-item">
                                <a class="nav-link active" href="#all" data-bs-toggle="tab">Heath Reward</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#user_referal" data-bs-toggle="tab">User Referral</a>
                            </li>
                        </ul>

                        <div class="tab-content bg-transparent">
                            <div id="all" class="active tab-pane fade in show">
                                <div class="table-responsive">
                                    <table class="table table-borderless border-top">

                                        <tbody>
                                            {
                                                userreward.length > 0 ? <>
                                                    {
                                                        userreward.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div class="d-flex justify-content-start align-items-center">
                                                                            {i + 1}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="d-flex flex-column">
                                                                            <p class="mb-0 fw-medium">{item?.steps} Steps</p>
                                                                            <small class="text-muted text-nowrap"><DateFormatter timestamp={item?.created_on} /></small>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="d-flex flex-column">
                                                                            <p class="mb-0 fw-medium">{item?.Reward} USDT</p>

                                                                        </div>
                                                                    </td>


                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </> : <>
                                                    <p>No data found...</p>
                                                </>
                                            }




                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div id="user_referal" class="tab-pane fade">
                                <div class="table-responsive">
                                    <table class="table table-borderless border-top">
                                        <tbody>
                                            {
                                                userreferal.length > 0 ? <>
                                                    {
                                                        userreferal.map((item, i) => {
                                                            return (
                                                                <tr key={i}>
                                                                    <td>
                                                                        <div class="d-flex justify-content-start align-items-center">
                                                                            {i + 1}
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="d-flex flex-column">
                                                                            <p class="mb-0 fw-medium">{item.referral_id}</p>
                                                                            <small class="text-muted text-nowrap"><DateFormatter timestamp={item.created_on} /></small>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div class="d-flex flex-column">
                                                                            <p class="mb-0 fw-medium">{item.reward} USDT</p>

                                                                        </div>
                                                                    </td>


                                                                </tr>
                                                            )
                                                        })
                                                    }
                                                </> : <>

                                                </>
                                            }




                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>








            </div>

        </div>
    )
}



