import React, { useEffect, useState } from 'react'
import { get_withdraw_fees, lb_withdraw_request, resend_otp, update_plan_end_date, user_details_two, withdraw_request } from '../common/Api'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

export default function LeaderShipBonusWithdrawal() {
    const ph = JSON.parse(localStorage.getItem('ph'));
    const navigation = useNavigate();

    const [email, setEmail] = useState(true)
    const [PageDetail, setPageDetail] = useState('wallet_page')

    const [info, setInfo] = useState({})

    const [userEmail, setUserEmail] = useState('')

    const [spiltWithdraw, setSpiltWithdraw] = useState(0)
    const [jwAmt, setJwAmt] = useState(0)

    const [amount, setAmount] = useState('')
    const [address, setAddress] = useState('')
    const [pin, setPin] = useState('')

    const [emailOtp, setEmailOtp] = useState('')
    const [version, setVersion] = useState(0)
    const [fee, setFee] = useState(0)
    const [received, setReceived] = useState(0)


    const get_user_detail = async () => {
        try {
            const data = await user_details_two(PageDetail)

            setInfo(data)
            setUserEmail(data.Email)
        } catch (error) {
            console.log(error)
        }
    }




    useEffect(() => {
        if (ph == 1) {
            navigation('/user/import/phrases')
        }

        get_user_detail()

    }, [ph])



    const handleSendOtp = async () => {
        const result = await resend_otp()

        toast(result.Msg)
    }



    const handleWidthdrawal = async () => {
        if (amount < 0) {
            return toast.error(`Please enter greater than 0`)
        }
        if (info.admin_stop_withdraw1 == 1) {
            toast.error('Withdrawal Under Maintance')
        } else {
            if (info?.LB_wallet > amount) {
                if (amount > 0) {
                    const data = {
                        Address: info.wallet_address,
                        Amount: amount,
                        Two_Fa: emailOtp,
                        User_PK: ph,
                        Wei_amount: jwAmt,
                        pin: pin,
                        premium_transfer_amt: 0,
                        price: spiltWithdraw,
                        security_type: "EMAIL",
                        stake_credit_converted: "0.0",
                        user_withdraw_request: "0.0",
                        wallet_type: "3",
                        AppVersion: "5.7.3"
                    }
                    const result = await lb_withdraw_request(data)
                    toast(result.Msg)
                }else{
                    toast.error(`Please enter greater than 0`)
                }






            } else {
                toast.error(`Insufficient Balance`)
            }

        }
    }

    const handleAmount = (e) => {

        const value = e.target.value;
        setAmount(e.target.value)
        if (info.market_price_details && info.LBWithdrawFee) {
            const withdrawFeesAmount = value * info.LBWithdrawFee / 100;
            console.log(withdrawFeesAmount)
            const spiltWithdraw = value - withdrawFeesAmount;
            const jwAmt = spiltWithdraw / info.market_price_details
            setJwAmt(jwAmt)
            setSpiltWithdraw(spiltWithdraw)
        } else {
            toast.error('Something went wrong! Please Contact Admin ')
        }


    }

    console.log(info)


    return (
        <div class="container-xxl flex-grow-1 container-p-y">
            <div class="row">
                <ToastContainer />



                <div class="col-lg-12 mb-4">
                    <div class="card h-100 px-4 pt-3">
                        <h4>Leadership Bonus Withdrawal ({info?.LB_wallet} $)</h4>
                        <div class="row justify-content-between align-items-center">
                            <div>
                                <div class="mb-3">
                                    <label for="amount" class="form-label">Amount(USDT)</label>
                                    <input type="number" class="form-control" id="fiat_value" placeholder="0.00"
                                        aria-describedby="" value={amount} onChange={(e) => handleAmount(e)} />

                                </div>




                            </div>
                        </div>


                    </div>

                </div>
                <div class="col-12 mb-4">
                    <div class="card h-100 px-4 pt-3">
                        <div class="row justify-content-between align-items-center">
                            <div>

                                <p class="mt-2">Community support : {fee} %
                                </p>

                                <div class="col-md-12 my-3">
                                    <label for="pin" class="form-label">Enter Pin</label>
                                    <input type="password" class="form-control" id="address" placeholder="Enter Your Pin" value={pin} onChange={(e) => setPin(e.target.value)}
                                        aria-describedby="" />

                                </div>
                                <div class="mb-4">



                                    {
                                        email && <>
                                            <div class="col-12 mb-4 mt-3 position-relative">
                                                <label for="2fa" class="form-label">Email</label>
                                                <input type="text" class="form-control" id="email" value={userEmail} aria-describedby="" onChange={(e) => setUserEmail(e.target.value)} readOnly={true} />
                                                <p style={{ cursor: "pointer", color: 'red' }} onClick={handleSendOtp}>Send OTP</p>

                                            </div>
                                            <div class="col-12 mb-4 mt-3 position-relative">
                                                <label for="2fa" class="form-label">Email OTP</label>
                                                <input type="number" class="form-control" id="emailOtp" placeholder="OTP" aria-describedby="" value={emailOtp} onChange={(e) => setEmailOtp(e.target.value)} />


                                            </div>
                                        </>
                                    }


                                </div>





                                <button type="button" class="btn btn-primary waves-effect waves-light mb-4" onClick={handleWidthdrawal}>Withdraw</button>
                            </div>
                        </div>


                        <div class="mt-3">


                            <div class="modal fade" id="modalScrollable" tabindex="-1" style={{ display: "none" }}
                                aria-hidden="true">
                                <div class="modal-dialog modal-dialog-scrollable" role="document">
                                    <div class="modal-content">
                                        <div class="modal-header">

                                        </div>
                                        <div class="modal-body">
                                            <p class="text-center fs-5 ">
                                                You Should have BNB Balance to Withdraw, Minimum balance of 0.00100000
                                            </p>
                                            <div class="row">
                                                <div class="col-6 golden_bg fs-6">
                                                    <div class="">
                                                        Heath Monthly Withdraw limit
                                                    </div>
                                                    <div class="">
                                                        600 - 750 USDT Per Month

                                                    </div>
                                                </div>
                                                <div class="col-6 golden_bg fs-6">
                                                    <div>
                                                        Referral Monthly Withdraw limit
                                                    </div>
                                                    <div class="">
                                                        1 - 800 USDT Per Month
                                                    </div>
                                                </div>
                                                <div class="col-12 text-center my-3">
                                                    <button type="button" class="btn btn-dark waves-effect waves-light"
                                                        data-bs-dismiss="modal">Understood</button>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>


                            <div class="content-backdrop fade"></div>
                        </div>

                    </div>

                </div>

            </div>


            <div class="layout-overlay layout-menu-toggle"></div>


            <div class="drag-target"></div>

        </div>
    )
}
