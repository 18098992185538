import React, { useEffect, useState } from 'react'
import Pagination from "react-js-pagination";
import { login_history } from '../common/Api';
import DateFormatter from './common/DateFormatter';

export default function LoginHistoryComponent() {
    const [page, setPage] = useState(1)
    const [data, setData] = useState([])
    const [count, setCount] = useState(null)
    const [activePage, setActivePage] = useState(1);

    const get_login_history = async () => {
        const data = await login_history(page)
        setData(data.Data)
        setCount(data.count)
    }

    useEffect(() => {
        get_login_history()
    }, [page])


    const handlePageChange = async (pageNumber) => {
        setActivePage(pageNumber)
        console.log(`active page is ${pageNumber}`);
        const data = await login_history(pageNumber)
        setData(data.Data)
        setCount(data.count)
    }

    console.log(data)

    return (
        <div class="container-xxl flex-grow-1 container-p-y">


            <div class="card mb-4 p-4 ">

              
                <h5>
                    Login History
                </h5>
                <div class="table-responsive">
                    <table class="table table-borderless border-top">
                        <thead class="border-bottom">
                            <tr>
                                <th>S.No.</th>
                                <th>Login At</th>
                                <th>Last Login</th>

                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length > 0 ? <>
                                    {
                                        data.map((items, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>
                                                        <div class="d-flex justify-content-start align-items-center">
                                                            {items.sno}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div class="d-flex flex-column">
                                                            <p class="mb-0 fw-medium"><DateFormatter timestamp={items?.created_on} /></p>
                                                            <small class="text-muted text-nowrap">IP:{items?.ip_address}</small>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <p class="mb-0 fw-medium"><DateFormatter timestamp={items?.modified_on} /></p>
                                                        <small class="text-muted text-nowrap">IP:{items?.ip_address1}</small>
                                                    </td>

                                                </tr>
                                            )
                                        })
                                    }

                                </> : <>
                                    <p>No data found...</p>
                                </>
                            }



                        </tbody>
                    </table>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={count}
                        pageRangeDisplayed={count / 10}
                        onChange={handlePageChange}
                    />
                </div>
            </div>




        </div>
    )
}
