import React from 'react'
import logonew from '../front/images/logonew.png'
import { Link } from 'react-router-dom'
import FrontHeader from './common/FrontHeader'

export default function HelpCenter() {
    return (
        <>
            <FrontHeader />
            <section class="jasonvision mt-5">
                <div class="container">
                    <div class="row my-5">
                        <div class="col-sm-12 ">

                            <h4>How do I check my JW Token balance?</h4>
                            <p class="mt-3">Once earned, your JW Token are automatically added to your balance. You can see your current balance of WEB3 WELLNESS in the Balance screen (just click on your balance in the top right corner of the app from the homescreen</p>

                            <h4>Do JW Token expire?</h4>
                            <p class="mt-3">Simple one - your JW Token don’t expire. but the Premium plan have 30 days validity. you should withdraw all the rewards before premium plan  validity expires .</p>

                            <h4>Can I Cashout my JW Token to /Bank Account?</h4>
                            <p class="mt-3">you can create account with  https://www.koinpark.com/  to convert JW to INR</p>

                            <h4>How to stop WEB 3 WELLNESS from running?</h4>
                            <p class="mt-3">If you want the app to completely stop and not generate any JW Token then you can force quit it (when you double tap on the home button and flick the app away).</p>

                            <h4>What if I uninstall the app and then reinstall it later? Will I lose my JW Token?</h4>
                            <p class="mt-3">If you uninstall the app (but don\&#39;t initiate a delete account request via the app), you will be able to reinstall it at a later date and access your old account. So long as you use the same email addres that you used when initially registering, everything will be the same when you log back in!</p>

                            <h4>where I can buy OR sell JW token ?</h4>
                            <p class="mt-3">please visit
                                https://jwtoken.org/
                                for all details regarding this.</p>

                            <h4>what is Validity of the app premium plan?</h4>
                            <p class="mt-3">Yes! there will be 30 days validity for all Premium plans purchased. after the validity is over. you have to again purchase premium plan for the next 30 days. you have the option to buy premium plans Monthly/quarterly/yearly as well.</p>

                            <h4>I don&#39;t have referral link to register? from where I can get it?</h4>
                            <p class="mt-3">Yes ! to register in the app referral is optional.</p>

                            <h4>where I can join the community for regular updates.</h4>
                            <p class="mt-3">You can join our telegram group for regular updates. join our telegram channel using link. .. https://t.me/web3wellness.</p>

                            <h4>how Can I withdraw my rewards?</h4>
                            <p class="mt-3">you can withdraw your funds 24x7. web3wellness app has option to create/Import  decentralized wallet. you can withdraw all funds to this wallet.
                                But don&#39;t forget to write down your backup phrases . we don&#39;t store it!!</p>

                            <h4>My last day steps are not showing.what should I do?</h4>
                            <p class="mt-3">Don&#39;t Worry! if your steps are recorded by AI you can recover your steps  link in the side bar option. Just click the 3 lines on top left of the screen &gt;&gt;i&quot;Recover your steps&quot;.</p>

                            <h4>what are the points where App will step count and USDT(JW) will reset?</h4>
                            <p class="mt-3">Web3wellness App Reset options:there are 2 conditions for app reset

                                1.App will expire every 30 days.All the step count and wallet balance will reset when validity expired.
                                2.If user wishes to change the plan in-between existing plan. New plan will get activated and previous plan benefits and validity will reset. It is advisable you should withdraw all the funds before buying the new plan again.</p>

                            <h4>I want to buy premium plan next months . what are the steps and precautions?</h4>
                            <p class="mt-3">There are 3 options to buy the premium plan for next month
                                A) health Rewards B) user Referrals  C) JW wallet
                                **If you want to buy the same plan again . It can be bought only with JW wallet (NOT WITH HEALTH OR USER REFERRALS).
                                **It is advised user should withdraw all the wallet funds if he want to buy the same plan again.
                                **It is advised you should withdraw one wallet when App remained 2 days to expire.Withdraw one wallet when App remained 1 day to expire. Buy the premium plan same time.</p>

                            <h4>what are the withdrawal conditions?</h4>
                            <p class="mt-3">please NOTE:
                                *All withdrawals in whole numbers only no decimals.
                                *Users can withdraw one wallet in 24 hours. Next withdrawal will be active after 24 hours after user withdrawal time.
                                *bnb balance is mandatory to cover network fees.
                                *11% of the total withdrawal amount will be credited into stake wallet . You can stake and get 7.4% monthly rewards.
                                *Stake withdrawals are subject to 30% credit to stake wallet and 70% credit to trust wallet. 30 % Stake wallet fund can be withdrawn at end of 27 months with vesting. If your stake closed before 27 month then stake fund can only be used for re-stake.</p>

                            <h4>when wallet fund reset where it will go.??</h4>
                            <p class="mt-3">When your wallet fund is reset . The USDT in wallet reset. All the funds reset at that time are reverted back to main Jw wallet from where we withdraw the user rewards and Health rewards. This reset wallet function help to support community.</p>

                            <h4>can I create  multiple accounts in the application.</h4>
                            <p class="mt-3">NO..
                                there is 0% tolerance on multiple accounts created . Multiple accounts will be removed from the system permanently. It will be applicable to all accounts whose plan purchased  after 10 July 2023.</p>

                            <h4>how Multiple accounts are checked ?</h4>
                            <p class="mt-3">It depends on various factors .There is no set of conditions on which we detect multiple accounts .any type of unusual activity once found by AI and rechecked  by our experts ,will be removed from system.There will be no support regrading this. please be aware and do not use multiple accounts in any manner to avoid de-activation of account.</p>

                        </div>
                    </div>
                </div></section>
        </>
    )
}
